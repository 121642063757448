:root {
  --wesbos: #ffc600;
}

.menu {
  display: none;

  &--is-visible {
    display: flex;
    flex-direction: column;
   
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 80px;
  }
}

.item {
 
  &__header {
    display: flex;
    align-items: baseline;
  }

  &__title {
    font-size: smaller;
    color: var(--wesbos);
    letter-spacing: 2px;
    margin: 0;
    max-width: 50%;
  }

  &__dots {
    flex: 1;
    border-bottom: 1px dashed #aaa;
    margin: 0 15px;
  }

  &__price {
    color: var(--wesbos);
    font-size: smaller;
  }

  &__description {
    margin-bottom: 40px;
    font-size: x-small;
  }
}

.buttons-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 35px;

  @media (min-width: 768px) {
    margin-bottom: 60px;
  }
}

.button {
  margin: 5px 15px;
  padding: 10px 20px;
  border: 1px solid var(--wesbos);
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 10px;
}
.button--is-active {
  background-color: #ffc600;
  color: #ffffff;
}
.highlight {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: var(--wesbos);
  border-radius: 3px;
  z-index: -1;
  transition: 0.24s;
}

* {
  box-sizing: border-box;
}

.divApp {
  margin: 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #fff;
}

.wrapper {
 width: 100%;
  margin: 0 auto;
  padding: 7px;
}

h2 {
  display: inline-block;
  border-bottom: 4px solid var(--wesbos);
  margin-top: 0;
  font: 34px cookie, cursive;
  margin-left: 18px;
}


