.text-ellipsis {
  display: -webkit-box; /* Flex-like davranış */
  -webkit-box-orient: vertical; /* Çok satır desteği */
  overflow: hidden; /* Taşmaları gizle */
  text-overflow: ellipsis; /* '...' göstermek için */
  -webkit-line-clamp: 2; /* Görünecek maksimum satır sayısı */
  line-clamp: 2; /* Görünecek maksimum satır sayısı */
  max-height: 4.5em; /* Yüksekliği belirle (satır başına 1.5em varsayıldı) */
}
.custom-tab-panel,
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 0rem !important;
}
.none-p-tabview .p-tabview-panels {
  display: none !important;
}
.img-size {
  max-height: 190px;
  min-width: 190px;
  object-fit: contain;
}
