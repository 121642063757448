html,
body,
#root {
  height: 100% !important;
  overflow: auto;
}
.t4-header {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  border-bottom-color: #549e83;
  border-bottom-style: double;
}
.t4-header img {
  border-radius: 10px;
  max-height: 100px;
  width: revert;
  max-width: 145px;
  object-fit: cover;
}
.t4-header-content {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.t4-header-title {
  font-family: MerryChristmasFlake, sans-serif;
  font-size: 53px;
  position: absolute;
  font-weight: 400;
  color: #549e83;
  line-height: 44px;
  text-align: center;
}
.t4-header-tableName {
  position: absolute;
  right: 3px;
  top: 74px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  background-color: #549e833d;
  border-radius: 5px;
  padding: 3px;
  box-shadow: rgb(126 183 163) 2px 2px 3px;
}
.t4-head {
  position: fixed;
  z-index: 1000000000;
  width: 100%;
  background-color: white;
}
.t4-category {
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  padding-bottom: 100px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}
.t4-category-item {
  cursor: pointer;
  border-color: #008e6d;
  border-radius: 11px;
  border-style: solid;
  border-width: 1px;
  width: 144px;
  min-width: 144px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-top: 0px;
  height: 120px;
  max-height: 120px;
  transition: background-color 0.7s ease, border-color 0.7s ease; /* Animasyon ekledik */
}
.select-category {
  background-color: #549e83;
  border-color: #549e83;
  color: white;
  transition: background-color 0.7s ease, border-color 0.7s ease; /* Geçiş animasyonu */
}
.t4-category-item img {
  width: 100%;
  height: 83px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}
.t4-category-item .title {
  margin: 0px;
  text-align: center;
  font-size: medium;
  overflow: hidden;
  line-height: 26px;
  white-space: nowrap;
  font-family: ui-monospace;
  color: #008e6d;
  font-weight: 600;
  width: 140px;
  text-overflow: ellipsis;
}

.t4-product-card {
  overflow: hidden;
  background-color: #ffffff;
  border-color: #549e8359;
  position: relative;
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-width: 193px;
  max-width: 193px;
  box-shadow: rgb(126 183 163) 1px 1px 0px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 569px) {
  .t4-product-card {
    border-radius: 10px;

    min-width: 193px;
    max-width: 193px;
  }
  .t4-category-item {
    min-width: 144px;
    max-width: 144px;
  }
  .t4-product-card-body {
    min-height: 160px;
    max-height: 160px;
  }
}
@media only screen and (max-width: 393px) {
  .btn {
    font-size: x-small !important;
  }
  .t4-header-title {
    font-size: 33px !important;
    line-height: 38px !important;
  }
  .t4-product-card {
    min-width: 49%;
    max-width: 49%;
  }
  .t4-category-item {
    min-width: 46%;
    max-width: 46%;
  }
}
@media only screen and (max-width: 370px) {
  .btn {
    font-size: x-small !important;
  }
  .t4-header {
    max-height: 100px !important;
  }
}
@media only screen and (max-width: 312px) {
  .t4-product-card {
    min-width: 90%;
  }
  .t4-category-item {
    min-width: 90%;
  }
  .t4-product-card-body {
    max-height: 200px !important;
    min-height: 200px !important;
  }
  .t4-product-card-body img {
    max-height: 170px !important;
    min-height: 170px !important;
  }
}

.t4-product {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  padding-top: 5px;
}
.t4-product-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  max-height: 170px;
  min-height: 170px;
}
.t4-product-card-counter {

  border-radius: 6px;

  background-color: #ffffffb5;

  border-color: #008d6278;
  border-style: solid;
  border-width: 2px;
}
.t4-product-card-body img {
  border-radius: 10px;
  max-width: 90%;
  max-height: 121px;
  min-height: 121px;
}
.t4-product-card-header {
  padding-left: 10px;
  padding-right: 10px;
}
.t4-product-card-title {
  text-align: center;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 19px;
  margin-block-start: 0px;
  color: #467f6b;
  margin-block-end: 0px;
  max-height: 36px;
  min-height: 36px;
}
.t4-product-card-price {
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  line-height: 25px;
  color: #467f6b;
  margin-block-start: 0px;
  margin-block-end: 0px;
}
.btn {
  background-color: #ffffff00;
  border-color: #549e83;
  border-style: solid;
  border-width: 1px;
  color: #549e83;
  border-radius: 3px;
  padding: 4px;
  font-size: small;
  margin: 1px;
  cursor: pointer;
  transition: background-color 0.4s ease, border-color 0.4s ease;
  margin-bottom: 5px;
}
button.btn:hover {
  background-color: #7ee0be85;
  border-color: #467f6b;
  transition: background-color 0.4s ease, border-color 0.4s ease;
}
.t4-product-card-footer {
  display: flex;
  justify-content: center;
  margin-top: auto;
}
.t4-back-button {
  border-color: #467f6b;
  border-style: solid;
  color: #467f6b;
  position: fixed;
  margin: 7px;
  margin-top: 12px;
  z-index: 100000000000;
  font-size: x-small;
}
.p-datascroller .p-datascroller-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
h6{
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-weight: normal;
}
