.mdropdown {
  color: black;
}
.mdropdown ul {
  max-height: 400px;
}
.custom-scrollbar {
  overflow-y: auto !important; /* Kaydırma işlevselliğini sağlamak için */
  touch-action: auto !important; /* Dokunmatik ekranlarda kaydırma işlevselliğini sağlar */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 15px !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  border-radius: 6px !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555 !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f191 !important;
}
.custom-scrollbar {
  touch-action: pan-y !important; /* Sadece dikey kaydırmayı destekler */
}