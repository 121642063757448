:where(.css-dev-only-do-not-override-9igs30).ant-card .ant-card-body {
  padding: 4px;
  border-radius: 0 0 8px 8px;
}
.car-product {
  background-color: white;
  box-shadow: 0 0 11px rgba(192, 192, 192, 0.774);
}
@media screen and (max-width: 500px) {
  .car-product {
    max-width: 100%;
  }
}
html {
  overflow: hidden;
  overflow-y: auto;
}
.product-card {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  flex: 1;
  max-width: 300px;
  min-height: 270px;
}
@media only screen and (max-width: 600px) {
  .product-card {
    min-width: 165px;
    max-width: 200px;
    flex: 1;
  }
}
@media only screen and (max-width: 360px) {
  .product-card {
    min-width: 80% !important;
    max-width: 80% !important;
    width: 80% !important;
    margin: auto;
  }
}
@media only screen and (max-width: 280px) {
  .product-card {
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
  }
}
.m-container {
}

.m-content {
  margin-top: 188px; /* Sabit üst bölümün yüksekliği kadar boşluk bırak */
  /* overflow: auto;
  height: 100vh; */
  padding-top: 10px;
}
.m-content2 {
  margin-top: 70px;
  padding-top: 10px;
}

.my-content {
  background: linear-gradient(
    0deg,
    rgb(40, 44, 52) 0%,
    rgb(17 0 32 / 80%) 100%
  );
  background-color: #ffffff00 !important;
  color: white;
  width: 520px;
  transform-origin: 287px 170px;
}

@media (max-width: 532px) {
  .my-content {
    width: 98% !important;
  }
}
@media (max-width: 312px) {
  .my-content {
    width: 98% !important;
    min-width: 310px;
  }
}
