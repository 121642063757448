.login-content {
  min-width: 700px;
  max-width: 700px;
} 
@media (max-width: 668px) {
    .login-content {
      min-width: 500px;
      max-width: 500px;
    }
  }
  @media (max-width: 500px) {
    .login-content {
      min-width: 400px;
      max-width: 400px;
    }
  }
@media (max-width: 420px) {
  .login-content {
    min-width: 300px;
    max-width: 300px;
  }
 
}
.btn-content{
  display: flex;
  justify-content: right;
  margin-top: 20px;
}