/* width */
::-webkit-scrollbar {
  width: 1px !important;
  height: 3px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100 !important; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888898 !important; 
  border-radius: 3px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important; 
}