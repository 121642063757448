$small-screen: 370px;
$medium-screen: 678px;
$large-screen: 1024px;

.nft {
  user-select: none;
  max-width: 200px;
  min-width: 200px;
  margin: 0px;
  border: 1px solid #ffffff22;
  background-color: #282c34;
  background: linear-gradient(
    0deg,
    rgba(40, 44, 52, 1) 0%,
    rgba(17, 0, 32, 0.5) 100%
  );
  box-shadow: 0 7px 20px 5px #00000088;
  border-radius: 0.7rem;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  overflow: hidden;
  transition: 0.5s all;
  @media (max-width: 582px) {
    max-width: 183px;
    min-width: 183px;
  }
  @media (max-width: 400px) {
    max-width: 168px;
    min-width: 168px;
  }
  @media (max-width: 350px) {
    max-width: 300px;
    min-width: 300px;
  }

  hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid #88888855;
    margin-top: 0;
  }
  ins {
    text-decoration: none;
  }
  .main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1px;

    h2 {
      margin-top: 5px;
      font: 17px cookie, cursive;
      margin-left: 13px;
      font-weight: 700;
      font-family: Sofia;
      color: white;
      padding: 1px;
      border-bottom: 1px solid #5a5757d1;
      min-height: 50px;
      word-wrap: break-word;
  word-break: break-all;
    }
    .tokenImage {
      border-radius: 0.5rem;
      max-width: 100%;
      height: 143px;
      object-fit: scale-down;
    }

    .description {
     
      margin: 0.5rem 0; 
      margin-left: 10px;
      color: #a89ec9;
      max-height: 71px;
      overflow-y: auto;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 12px;
      // min-height: 100px;
    }
    .tokenInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price {
        display: flex;
        align-items: center;
        color: #ee83e5;
        font-weight: bold;
        margin-left: 10px;
        font-family: Arial, Helvetica, sans-serif;
        ins {
          margin-right: 0.5rem;
        }
      }
      .duration {
        display: flex;
        align-items: center;
        color: #a89ec9;
        margin-right: 0.2rem;
        font-family: Sofia;
        ins {
          margin: 0.5rem;
          margin-bottom: 0.4rem;
        }
      }
    }
   
  } .creator {
      display: flex;
      align-items: center;
      margin-top: 2px;
      margin-bottom: 5px;
      ins {
        color: #a89ec9;
        text-decoration: none;
      }
      .wrapper {
        display: flex;
        align-items: center;
        border: 1px solid #ffffff22;
        padding: 0.3rem;
        margin: 0;
        margin-right: 0.5rem;
        border-radius: 100%;
        box-shadow: inset 0 0 0 4px #000000aa;
        img {
          border-radius: 100%;
          border: 1px solid #ffffff22;
          width: 2rem;
          height: 2rem;
          object-fit: cover;
          margin: 0;
        }
      }
    }
  ::before {
    position: fixed;
    content: "";
    box-shadow: 0 0 100px 40px #ffffff08;
    top: -10%;
    left: -100%;
    transform: rotate(-45deg);
    height: 60rem;
    transition: 0.7s all;
  }
}

.bg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h1 {
    font-size: 20rem;
    font-family: Sofia;
    filter: opacity(0.5);
  }
}
.ui-title{
  font-family: Arial, Helvetica, sans-serif !important;
}