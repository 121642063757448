.mobile {
  // Mobil ekranlar için scale ayarlaması
  @media (max-width: 768px) {
    transform: scale(1); // Mobil cihazlar için %80 ölçeklendirme
  }

  // Daha küçük mobil ekranlar (örneğin 480px ve altı)
  @media (max-width: 480px) {
    transform: scale(1); // Daha küçük mobil cihazlar için %60 ölçeklendirme
    font-size: x-small;
  }
}

.p-sm-input .p-inputtext {
  padding: 0.2rem 0.2rem !important;
  max-width: 100% !important;
}
.p-sm-button .p-button.p-button-icon-only {
  width: 2rem;
  padding: 0rem 0 !important;
}
.p-sm-button .pi {
  font-size: 0.7rem !important;
}

.p-button.p-button-sm {
  font-size: small !important;
  padding: 0.55625rem 1.04375rem !important;
}
.ant-layout-header {
  line-height: 28px !important;
}

.p-menu .p-submenu-header {
  padding: 0.35rem 1rem !important;
  font-size: small !important;
}
.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
  padding: 0.45rem 1rem !important;
  font-size: small !important;
}
.p-menu {
  top: 48.4062px !important;
}

.p-inputtext {
  font-size: small !important;
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}
.p-paginator .p-dropdown {
  margin-left: 1rem !important;
  height: 2rem !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.35rem 1rem !important;
  font-size: small !important;
  border-bottom: rgb(229 231 235) 1px solid !important;
}
.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  background: #e5e7ebc9 !important;
}

p {
  margin-block-start: 0.5em !important;
  margin-block-end: 0.5em !important;
}

/* Scrollbar'ın genel alanı */
::-webkit-scrollbar {
  width: 7px !important; /* Dikey scroll için genişlik */
  height: 7px !important; /* Yatay scroll için yükseklik */
}

/* Scrollbar'ın arka planı */
::-webkit-scrollbar-track {
  background: #f0f0f0 !important; /* Arka plan rengi */
  border-radius: 10px !important;
}

/* Scrollbar'ın kaydırma kısmı */
::-webkit-scrollbar-thumb {
  background: #888 !important; /* Kaydırma çubuğu rengi */
  border-radius: 10px !important; /* Köşeleri yuvarlama */
}

/* Scrollbar üzerine geldiğinizde */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important; /* Hover efekti */
}

.p-badge.p-badge-lg {
  font-size: 1rem !important;
  min-width: 1.5rem !important;
  height: 1.5rem !important;
  line-height: 1.5rem !important;
}
.ant-content-my {
  overflow-y: auto !important;
  overflow-x: hidden;
}

.p-button .p-button-link:hover {
  text-decoration: none !important;
}
.p-button .p-button-label:hover {
  text-decoration: none !important;
}
