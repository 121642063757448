.basket-add-footer {
  display: flex;
  gap: 10px;
}
label{
  font-size: small;
}
.basket-add-header {
  display: flex;
  gap: 5px;
  font-weight: bold;
  i {
    line-height: 2;
  }
}
