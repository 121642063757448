.p-confirm-popup .p-confirm-popup-content {
  padding: 0.3rem !important;
}
.p-confirm-popup .p-confirm-popup-footer {
  padding: 0 0.5rem 0.5rem 0.5rem !important;
}

.confirm-popup-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  .confirm-popup-header {
    display: flex;
    gap: 5px;
    font-weight: bold;
    font-size: small;
    i {
      line-height: 2;
    }
  }
  .confirm-popup-footer {
    display: flex;
    gap: 10px;
  }
}
