.t4-detail-contain {
  display: flex;
  flex-direction: column;
  gap: 5px;
  .t4-detail-content {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
    align-items: center;
    img {
      max-width: 150px;
      object-fit: contain;
    }
    p {
      flex: 2;
      min-height: 113px;
      max-height: 133px;
      overflow: auto;
    }
  }

  .t4-detail-foot {
    display: flex;
    gap: 5;
  }
}

@media (max-width: 375px) {
  .t4-detail-content {
    flex-direction: column;
    img {
      width: 90%;
    }
  }
}
