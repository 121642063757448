@function gray($color) {
  @return rgb($color, $color, $color);
}

$red: rgb(239, 83, 80);
$orange: rgb(255, 160, 0);
$yellow: rgb(253, 216, 53);
$green: rgb(42, 252, 152);
$indigo: rgb(57, 73, 171);
$violet: rgb(103, 58, 183);

/* -- */

$blue: rgb(66, 165, 245);
html {
  // overflow: hidden;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 181px;
  display: flex;
  align-items: center;
  background: radial-gradient(
    circle,
    rgba(63, 94, 251, 1) 0%,
    rgba(252, 70, 107, 1) 100%
  );
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
  justify-content: center;
  z-index: 1000;
  box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
}
.head-title {
  color: white !important;
  margin-left: 10;
  font-family: BoldGroovy;
  font-weight: normal;
  font-size: 38px;
}
@media (max-width: 342px) {
  .head-title {
    font-size: 28px;
  }
}
.header2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  background: radial-gradient(
    circle,
    rgba(63, 94, 251, 1) 0%,
    rgba(252, 70, 107, 1) 100%
  );
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
  justify-content: center;
  z-index: 1000;
}
body {
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px;

  &::-webkit-scrollbar-track {
    background-color: gray(30);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(white, 0.2);
    border-radius: 100px;
  }

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }
}

.background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(255 255 255 / 63%);
}

.clear-button {
  backdrop-filter: blur(3px);
  background-color: rgba(white, 0.1);
  border: 1px solid rgba(white, 0.1);
  border-radius: 100px;
  box-shadow: 2px 2px 2px rgba(rgba(0, 0, 0, 0.342), 0.1);
  cursor: pointer;

  &:hover {
    background-color: rgba(white, 0.2);
    border: 1px solid rgba(white, 0.3);
  }
}

#app {
  background-color: gray(30);
  max-width: 100%;

  &.logged-out {
    #app-info {
      margin-left: 40px;
      opacity: 1;
      transform: translateX(0%);
    }

    #app-background {
      cursor: pointer;
    }

    #sign-in-button-wrapper {
      opacity: 1;
      pointer-events: all;
      transform: translate(-50%, -40px);
    }
  }

  &.logging-in,
  &.verifying-log-in,
  &.log-in-error {
    #app-background {
      #app-background-image {
        filter: blur(8px);
        transform: scale(1.2);
      }
    }
  }

  &.logging-in,
  &.log-in-error {
    #app-pin-wrapper {
      opacity: 1;
      pointer-events: all;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  &.verifying-log-in {
    #app-loading-icon {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  &.log-in-error {
    #app-pin-wrapper {
      #app-pin {
        .app-pin-digit {
          background-color: rgba($red, 0.05);
          border-color: rgba($red, 0.5);
        }
      }
    }
  }

  &.logged-in {
    #app-menu {
      overflow: initial;
      opacity: 1;
      pointer-events: all;
      transform: translateY(0%);
    }
  }

  .scrollable-component {
    cursor: grab;
    overflow: auto;
    user-select: none;
    width: 100%;

    &:active {
      cursor: grabbing;
    }

    &::-webkit-scrollbar {
      height: 0px;
      width: 0px;
    }
  }

  #app-loading-icon {
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, 0%);
    transition: opacity 250ms, transform 250ms;
    z-index: 2;

    i {
      animation: 2s spin ease-in-out infinite;
      color: white;
      font-size: 2em;
    }
  }

  #app-background {
    height: 100%;
    left: 0px;
    overflow: hidden;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1;

    #app-background-image {
      // background-image: url($backgroundImage);
      height: 100%;
      transition: filter 250ms, transform 250ms;
      width: 100%;
    }
  }

  #app-info {
    bottom: 0px;
    left: 0px;
    margin: 40px;
    margin-left: 0px;
    opacity: 0;
    position: absolute;
    transform: translateX(-100%);
    transition: margin 250ms, opacity 250ms, transform 250ms;
    z-index: 2;
  }

  .user-status-button {
    cursor: pointer;
    margin-top: 10px;
    outline: none;
    padding: 10px;
    width: 100px;

    i {
      color: gray(245);
      font-size: 1.25em;
    }
  }

  #sign-in-button-wrapper {
    bottom: 0px;
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, 40px);
    transition: opacity 250ms, transform 250ms;
    z-index: 2;

    #sign-in-button {
      &:not(:hover) {
        animation: bounce 3s infinite;
        animation-delay: 3s;
      }
    }
  }

  .info {
    align-items: flex-end;
    display: flex;

    .time {
      color: gray(245);
      font-size: 6em;
      height: 80px;
      line-height: 80px;
      text-shadow: 2px 2px 2px rgba(black, 0.1);
    }

    .weather {
      display: inline-flex;
      height: 20px;
      margin-bottom: 6px;
      margin-left: 20px;

      i,
      span {
        align-items: center;
        display: inline-flex;
      }

      i {
        color: $yellow;
        font-size: 0.9em;
      }

      span {
        color: white;
      }

      .weather-type {
        height: 20px;
      }

      .weather-temperature-value {
        font-size: 1.5em;
        height: 20px;
        margin-left: 5px;
      }

      .weather-temperature-unit {
        align-items: flex-start;
        font-size: 0.8em;
        margin-left: 3px;
      }
    }
  }

  .reminder {
    display: flex;
    gap: 6px;
    margin-top: 10px;

    i,
    div {
      display: inline-flex;
    }

    i {
      color: gray(245);
      font-size: 0.8em;
      height: 12px;
      line-height: 12px;
    }

    span {
      color: rgba(white, 0.8);
      font-size: 1.1em;
    }

    .reminder-icon {
      align-items: center;
      height: 20px;
    }

    .reminder-time {
      align-items: flex-end;
      color: gray(30);
      font-size: 0.8em;
      height: 20px;
    }
  }

  #quick-nav {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    overflow: auto;
    padding-bottom: 5px;
    width: 100%;
    z-index: 3;

    .quick-nav-item {
      padding: 10px 20px;

      &:last-of-type {
        margin-right: 10px;
      }

      .quick-nav-item-label {
        color: gray(245);
        text-shadow: 0px 0px 2px rgba(black, 0.1);
      }
    }
  }

  #youtube-link {
    align-items: center;
    display: inline-flex;
    gap: 5px;
    margin-top: 10px;
    padding: 10px 20px;
    text-decoration: none;

    i,
    span {
      height: 20px;
      line-height: 20px;
    }

    i {
      color: $red;
    }

    span {
      color: white;
    }
  }

  .menu-section {
    padding: 0px;
    margin-top: 0px;

    .menu-section-title {
      align-items: center;
      display: flex;
      gap: 0px;

      i,
      span {
        color: gray(245);
      }

      i {
        font-size: 1em;
      }

      .menu-section-title-text {
        color: rgba(white, 0.8);
        font-size: 1.25em;
      }
    }

    .menu-section-content {
      margin-top: 0px;
      padding-top: 0px;
      flex-wrap: wrap;
    }
  }

  #restaurants-section {
    .menu-section-content {
      max-height: calc(100vh - 200px);
      overflow: auto;
      display: flex;
      gap: 1em;
      justify-content: space-evenly;

      .restaurant-card {
        border-radius: 10px;
        box-shadow: 2px 2px 4px rgba(black, 0.25);
        cursor: pointer;
        height: 14vw;
        max-height: 240px;
        position: relative;
        transition: transform 250ms;
        flex: 1;
        min-width: 340px;

        &:hover {
          transform: translateY(-5px);

          .restaurant-card-content {
            .restaurant-card-content-items {
              margin-bottom: 30px;
            }
          }
        }

        .restaurant-card-content {
          background: linear-gradient(to top, rgba(black, 0.8), transparent);
          border-radius: 10px;
          height: 100%;
          width: 100%;

          .restaurant-card-content-items {
            bottom: 0px;
            display: flex;
            flex-direction: column;
            margin: 20px;
            position: absolute;
            right: 0px;
            text-align: right;
            transition: margin 250ms;
            width: 100%;
            .restaurant-card-title {
              color: gray(245);
              font-size: 1.5em;
            }

            .restaurant-card-desc {
              color: $blue;
              font-size: 0.9em;
            }
          }
        }
      }
    }
  }
  /* App.css */
  @keyframes custom-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .app {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
  }

  .custom-spin-icon {
    font-size: 24px;
    color: #ffffff !important;
    animation: custom-spin 1s linear infinite;
  }
  #weather-section {
    .menu-section-content {
      display: flex;
      gap: 1em;
      padding: 5px 0px;
      width: 100%;

      .day-card {
        backdrop-filter: blur(3px);
        background-color: rgba(white, 0.1);
        border: 1px solid rgba(white, 0.2);
        border-radius: 10px;
        box-shadow: 2px 2px 4px rgba(black, 0.25);
        height: 8vw;
        max-height: 160px;
        min-height: 140px;
        min-width: 180px;
        position: relative;
        transition: transform 250ms;
        width: calc(100% / 7);

        &:last-of-type {
          margin-right: 5px;
        }

        .day-card-content {
          display: flex;
          flex-direction: column;
          height: calc(100% - 20px);
          justify-content: space-evenly;
          padding: 10px;

          i,
          span {
            color: gray(245);
            text-align: center;
          }

          .day-weather-temperature {
            align-items: flex-start;
            display: flex;
            font-size: 0.9em;
            justify-content: center;

            .day-weather-temperature-unit {
              font-size: 0.8em;
              margin-left: 3px;
            }
          }

          .day-weather-icon {
            font-size: 3.5em;
            text-shadow: 2px 2px 2px rgba(black, 0.1);

            &.sunny {
              color: $yellow;
            }

            &.rainy,
            &.stormy {
              color: $blue;
            }
          }

          .day-name {
            font-size: 0.9em;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  :where(.css-dev-only-do-not-override-19g24c1).ant-spin-nested-loading
    > div
    > .ant-spin
    .ant-spin-text {
    position: absolute;
    top: 50%;
    width: 100%;
    text-shadow: 0 1px 2px #000000;
    color: #ffffff;
  }
  #tools-section {
    .menu-section-content {
      display: flex;

      align-items: center;
      gap: 0.5em;

      .tool-card {
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 2px 2px 4px rgba(black, 0.25);
        cursor: pointer;
        height: 8vw;
        max-height: 80px;
        min-height: 80px;
        overflow: hidden;
        position: relative;
        transition: transform 250ms;
        width: calc(100% / 6);
        box-shadow: 0 0 7px #4b484882;
        margin: 5px;
        &:hover {
          transform: translateY(-5px);

          .tool-card-background {
            filter: grayscale(25%);
          }
        }

        .tool-card-background {
          border-radius: 10px;

          height: 100%;
          left: 0px;
          opacity: 1;
          position: absolute;
          top: 0px;
          transition: filter 250ms;
          width: 100%;
        }

        .tool-card-content {
          // background: linear-gradient(
          //   to right,
          //   rgba(black, 0.4),
          //   rgba(black, 0.1)
          // );
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          height: calc(100% - 40px);
          justify-content: space-between;
          padding: 20px;
          position: relative;
          width: calc(100% - 40px);
          z-index: 2;

          .tool-card-content-header {
            display: flex;
            flex-direction: column;
            gap: 2px;

            .tool-card-label {
              color: $blue;
              font-size: 0.8em;
              text-transform: uppercase;
            }

            .tool-card-name {
              //color: gray(245);
              font-size: 1.25em;
              color: rgb(255 255 255);
              text-shadow: 0px 0px 9px rgb(0 0 0 / 80%);
            }
          }

          .tool-card-icon {
            color: gray(245);
            font-size: 2em;
          }
        }
      }
    }
  }

  #movies-section {
    .menu-section-content {
      display: flex;
      gap: 1em;

      #movie-card-1 {
        .movie-card-content {
          background: linear-gradient(
            to top,
            rgba($indigo, 0.4),
            transparent,
            rgba(black, 0.4)
          );
        }
      }

      #movie-card-2 {
        .movie-card-content {
          background: linear-gradient(
            to top,
            rgba($violet, 0.4),
            transparent,
            rgba(black, 0.4)
          );
        }
      }

      #movie-card-3 {
        .movie-card-content {
          background: linear-gradient(
            to top,
            rgba($red, 0.4),
            transparent,
            rgba(black, 0.4)
          );
        }
      }

      #movie-card-4 {
        .movie-card-content {
          background: linear-gradient(
            to top,
            rgba($green, 0.4),
            rgba(black, 0.1),
            rgba(black, 0.4)
          );
        }
      }

      .movie-card {
        background-color: gray(30);
        border-radius: 10px;
        box-shadow: 2px 2px 4px rgba(black, 0.25);
        cursor: pointer;
        height: 40vw;
        max-height: 600px;
        min-height: 460px;
        min-width: 260px;
        overflow: hidden;
        position: relative;
        transition: transform 250ms;
        width: calc(100% / 4);

        &:hover {
          transform: translateY(-5px);

          .movie-card-background {
            transform: scale(1.05);
          }

          .movie-card-content {
            i {
              transform: translate(-20%, -20%) scale(1.2);
            }
          }
        }

        .movie-card-background {
          border-radius: 10px;
          height: 100%;
          left: 0px;
          position: absolute;
          top: 0px;
          transition: transform 250ms;
          width: 100%;
          z-index: 1;
        }

        .movie-card-content {
          background: linear-gradient(
            to top,
            rgba(black, 0.5),
            rgba(black, 0.1),
            rgba(black, 0.4)
          );
          border-radius: 10px;
          height: 100%;
          position: relative;
          z-index: 2;

          .movie-card-info {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 30px;

            span {
              text-shadow: 2px 2px 2px rgba(black, 0.1);
            }

            .movie-card-title {
              color: gray(245);
              font-size: 2em;
            }

            .movie-card-desc {
              color: gray(200);
              font-size: 0.9em;
            }
          }

          i {
            bottom: 0px;
            color: gray(245);
            font-size: 5em;
            padding: 30px;
            position: absolute;
            right: 0px;
            text-shadow: 2px 2px 2px rgba(black, 0.1);
            transition: transform 250ms;
          }
        }
      }
    }
  }

  #app-pin-wrapper {
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -30%) scale(0.8);
    transition: opacity 250ms, transform 250ms;
    z-index: 2;

    #app-pin-label {
      color: gray(245);
      font-size: 0.9em;
      margin: 10px;
      text-shadow: 2px 2px 2px rgba(black, 0.1);

      #app-pin-cancel-text {
        cursor: pointer;
        margin-left: 2px;

        &:hover {
          text-decoration: underline;
        }
      }

      #app-pin-error-text {
        color: $red;
      }
    }

    #app-pin-hidden-input {
      background-color: transparent;
      border: none;
      height: 0px;
      outline: none;
      pointer-events: none;
      position: absolute;
      width: 0px;
    }

    #app-pin {
      display: flex;
      gap: 10px;

      .app-pin-digit {
        align-items: center;
        background-color: rgba(white, 0.05);
        border: 1px solid rgba(white, 0.2);
        border-radius: 10px;
        box-shadow: 2px 2px 2px rgba(black, 0.06);
        display: inline-flex;
        font-size: 3em;
        height: 80px;
        justify-content: center;
        position: relative;
        transition: background-color 250ms, border-color 250ms;
        width: 60px;

        &:after,
        &:before {
          box-shadow: 2px 2px 2px rgba(black, 0.06);
          content: "";
          position: absolute;
          transition: opacity 250ms, transform 250ms;
          z-index: 2;
        }

        &:before {
          background-color: gray(245);
          border-radius: 10px;
          bottom: 0px;
          height: 3px;
          left: 15%;
          opacity: 0;
          transform: translateY(0px);
          width: 70%;
        }

        &:after {
          background-color: gray(245);
          border-radius: 20px;
          height: 20px;
          opacity: 0;
          transform: scale(0.25);
          width: 20px;
        }

        &.focused {
          &:before {
            animation: blink 2s ease-in-out infinite;
            opacity: 1;
            transform: translateY(-10px);
          }
        }

        &.hidden {
          &:after {
            opacity: 1;
            transform: scale(1);
          }

          .app-pin-digit-value {
            opacity: 0;
            transform: scale(0.25);
          }
        }

        .app-pin-digit-value {
          color: gray(245);
          transition: opacity 250ms, transform 250ms;
        }
      }
    }
  }

  #app-menu {
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    position: relative;
    transform: translateY(-10%);
    transition: opacity 250ms, transform 250ms;
    z-index: 2;

    #app-menu-content-wrapper {
      // background: linear-gradient(to bottom, transparent, gray(30));
      margin-top: 0px;

      padding: 80px;
      padding-top: 0px;

      #app-menu-content {
        margin: auto;
        max-width: 1600px;
        position: relative;

        #app-menu-content-header {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

:where(.css-dev-only-do-not-override-13qo8mb).ant-float-btn-group-square
  .ant-float-btn-square:last-child {
  border-end-start-radius: 8px;
  border-end-end-radius: 8px;
  width: 90px;
}

.FloatButtonsss {
  right: 90px;
}

@media (max-width: 1300px) {
  #app {
    &.logged-out {
      #sign-in-button-wrapper {
        transform: translate(-40px, 0px);
      }
    }

    #sign-in-button-wrapper {
      bottom: 40px;
      left: auto;
      right: 0px;
      transform: translate(40px, 0px);
    }

    #app-menu {
      #app-menu-content-wrapper {
        padding: 0px;

        #app-menu-content {
          #restaurants-section {
            .menu-section-content {
              flex-wrap: wrap;

              .restaurant-card {
                height: 30vw;
                max-height: 300px;
                position: relative;
                width: calc(50% - 0.5em);
              }
            }
          }

          #tools-section {
            .menu-section-content {
              flex-wrap: wrap;

              .tool-card {
                width: calc(33.33% - 0.69em);
              }
            }
          }
        }
      }
    }
  }
  :where(.css-dev-only-do-not-override-13qo8mb).ant-float-btn-group-square
    .ant-float-btn-square:last-child {
    border-end-start-radius: 8px;
    border-end-end-radius: 8px;
    width: 70px;
  }

  .FloatButtonsss {
    right: 80px;
  }

  .custom-float-button.cart {
    bottom: 190px !important;
  }

  .custom-float-button {
    min-width: 70px !important;
  }
  .custom-float-button-title {
    font-size: 12px !important;
  }
}

@media (max-width: 600px) {
  #app {
    .info {
      .time {
        font-size: 4em;
        height: 60px;
        line-height: 60px;
      }
    }

    .user-status-button {
      width: 60px;
    }

    #app-menu {
      #app-menu-content-wrapper {
        #app-menu-content {
          #restaurants-section {
            .menu-section-content {
              flex-direction: row;
              display: flex;
              gap: 1em;

              .restaurant-card {
                height: 40vw;
                position: relative;
                width: 100%;
                margin-bottom: 10px;
              }
            }
          }

          #tools-section {
            .menu-section-content {
              flex-wrap: wrap;

              .tool-card {
                width: calc(50% - 0.5em);
              }
            }
          }
        }
      }
    }
  }

  .FloatButtonsss {
    right: 70px;
  }
  :where(.css-dev-only-do-not-override-13qo8mb).ant-float-btn-group-square
    .ant-float-btn-square:last-child {
    border-end-start-radius: 8px;
    border-end-end-radius: 8px;
    width: 70px;
  }
  .custom-float-button.cart {
    bottom: 170px !important;
  }

  .custom-float-button {
    min-width: 70px !important;
  }
  .custom-float-button-title {
    font-size: 12px !important;
  }
}

@media (max-width: 400px) {
  #app {
    #app-menu {
      #app-menu-content-wrapper {
        #app-menu-content {
          #tools-section {
            .menu-section-content {
              flex-wrap: wrap;

              .tool-card {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .custom-float-button.cart {
    bottom: 150px !important;
  }

  .custom-float-button {
    min-width: 60px !important;
  }
  .custom-float-button-title {
    font-size: 9px !important;
  }

  :where(.css-dev-only-do-not-override-13qo8mb).ant-float-btn-group-square
    .ant-float-btn-square:last-child {
    border-end-start-radius: 8px;
    border-end-end-radius: 8px;
    width: 60px;
  }
}
