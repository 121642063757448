.theme-card-high {
  background: var(--cyan-50) !important;
}
.theme-card-high-select {
  background: var(--cyan-100) !important;
}
.theme-card-error {
  background: var(--red-200) !important;
  color: #fff !important;
}
.theme-card-default {
  background: #fff !important;
}
.theme-card-primary {
  background: var(--primary-color) !important;
}
.theme-card-success {
  background: var(--green-200) !important;
}
.p-card{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}
.p-card .p-card-body {
  padding: 0.5rem !important;
}
.p-card .p-card-content {
  padding: 0.5rem 0 !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}
.p-card .p-card-title {
  font-size: medium !important;
  font-weight: 700 !important;
  margin-bottom: 0rem !important;
}

.outline-success {
  border-color: #22c55e59;
  border-style: solid;
  border-width: 2px;
}
.outline-error {
  border-color: #ef44448a;
  border-style: solid;
  border-radius: 5px;
  border-width: 2px;
}
